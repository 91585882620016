import { Footer, Header, PageHeader } from "../../components";
import { useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const UserForgotPassword = () => {
  const [requestSent, setRequestSent] = useState(false);
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email can not be empty")
      .email("Please enter a valid Email address"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = (data) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "user/forgotpassword/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ email: data.email }),
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.success) {
          setRequestSent(true);
        }
      })
      .catch((error) => {
        setError("server", { message: error.message });
      });
  };

  return (
    <div>
      <Header />
      <section id="login-sec" className="projects">
        <PageHeader title="Reset Password" description="Enter your email" />
      </section>
      <section className="mb-5">
        <div className="newSec">
          <section>
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center h-100">
                <div className="col-md-8 col-lg-7 col-xl-6">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/login.svg`}
                    className="img-fluid imgUnselectable"
                    alt="login"
                  />
                </div>
                <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
                  {!requestSent ? (
                    <>
                      <form
                        className="form mt-3"
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <div className="form-floating mb-4">
                          <input
                            type="email"
                            name="email"
                            placeholder="name@email.com"
                            id="email"
                            className={`form-control form-control-lg ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            {...register("email")}
                          />
                          <label className="form-label" htmlFor="email">
                            Email address
                          </label>
                          <div className="invalid-feedback">
                            {errors.email?.message}
                          </div>
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary btn-lg col-12 mb-4"
                        >
                          Send Recovery Link
                        </button>

                        {errors.server && (
                          <div className="alert alert-danger">
                            {errors.server?.message}
                          </div>
                        )}
                      </form>{" "}
                    </>
                  ) : (
                    <>
                      <p>Reset password request submitted!</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default UserForgotPassword;
