import { Footer, Header, PageHeader } from "../../components";
import teachersData from "./TeachersData.json";
import AboutCard from "./AboutCard";

import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const TeachersList = () =>
    teachersData.map((teacher, i) => (
      <>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 card-div">
          <AboutCard key={i} teacher={teacher} />
        </div>
      </>
    ));

  return (
    <>
      <Header />
      <section className="about">
        <PageHeader title="About Us" description="Meet the teachers" />
        <motion.div
          className="newSec"
          ref={ref}
          initial={{ y: "-10vw", opacity: 0 }}
          animate={inView ? { y: 0, opacity: 1 } : { y: "-10vw", opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <div className="row">
            <TeachersList />
          </div>
        </motion.div>
      </section>
      <Footer />
    </>
  );
};

export default About;
