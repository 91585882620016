import { useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/auth";

export default function Unauthorized() {
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";

  const [authed, data] = useAuth();

  useEffect(() => {
    if (authed) {
      let typeOfUnAuth = location.state?.type || "generic";

      if (typeOfUnAuth === data?.type) {
        navigate(from);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authed, data?.type, from, location.state?.type]);

  return (
    <div>
      <h3>
        You are not Authorized to access <code>{from}</code>
      </h3>
      <Link className="btn btn-primary" to="/prof/login">
        login as prof
      </Link>
      <Link className="btn btn-primary" to="/user/login">
        login as user
      </Link>
    </div>
  );
}
