function QmcAll(props) {
  return (
    <div>
      <div className={"text-align-left " + props.classes}>
        <h5>{"Question " + props.qNr + ": " + props.question}</h5>
      </div>
      <div className="input-group mt-3">
        {props.options.map((option, index) => {
          return (
            <div
              className="form-check form-check-inline"
              key={props.keyName + option + index}
            >
              <input
                name={props.name + "-" + index}
                className="form-check-input"
                type="checkbox"
                id={props.name + "-" + index}
                checked={props.checked[index] || ""}
                onChange={props.handleFunction}
              />
              <label
                className="form-check-label"
                htmlFor={props.name + "-" + index}
              >
                {option}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default QmcAll;
