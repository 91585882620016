import { PageHeader, ResultTD } from "../../components";
import * as go from "gojs";
import { ReactDiagram } from "gojs-react";

function CpmVis(props) {
  /**
   * Diagram initialization method, which is passed to the ReactDiagram component.
   * This method is responsible for making the diagram and initializing the model and any templates.
   * The model's data should not be set here, as the ReactDiagram component handles that via the other props.
   */
  function initDiagram() {
    // Since 2.2 you can also author concise templates with method chaining instead of GraphObject.make
    // For details, see https://gojs.net/latest/intro/buildingObjects.html
    const $ = go.GraphObject.make; // for more concise visual tree definitions

    // colors used, named for easier identification
    var blue = "#0288D1";
    var pink = "#B71C1C";
    var pinkfill = "#F8BBD0";
    var bluefill = "#B3E5FC";

    const myDiagram = $(go.Diagram, {
      initialAutoScale: go.Diagram.Uniform,
      layout: $(go.LayeredDigraphLayout),
    });

    // The node template shows the activity name in the middle as well as
    // various statistics about the activity, all surrounded by a border.
    // The border's color is determined by the node data's ".critical" property.
    // Some information is not available as properties on the node data,
    // but must be computed -- we use converter functions for that.
    myDiagram.nodeTemplate = $(
      go.Node,
      "Auto",
      $(
        go.Shape,
        "Rectangle", // the border
        { fill: "white", strokeWidth: 2 },
        new go.Binding("fill", "critical", (b) => (b ? pinkfill : bluefill)),
        new go.Binding("stroke", "critical", (b) => (b ? pink : blue))
      ),
      $(
        go.Panel,
        "Table",
        { padding: 0.5 },
        $(go.RowColumnDefinition, { column: 1, separatorStroke: "black" }),
        $(go.RowColumnDefinition, { column: 2, separatorStroke: "black" }),
        $(go.RowColumnDefinition, {
          row: 1,
          separatorStroke: "black",
          background: "white",
          coversSeparators: true,
        }),
        $(go.RowColumnDefinition, { row: 2, separatorStroke: "black" }),
        $(
          go.TextBlock, // earlyStart
          new go.Binding("text", "earlyStart"),
          { row: 0, column: 0, margin: 5, textAlign: "center" }
        ),
        $(go.TextBlock, new go.Binding("text", "length"), {
          row: 0,
          column: 1,
          margin: 5,
          textAlign: "center",
        }),
        $(
          go.TextBlock, // earlyFinish
          new go.Binding("text", "", (d) =>
            (d.earlyStart + d.length).toFixed(0)
          ),
          { row: 0, column: 2, margin: 5, textAlign: "center" }
        ),

        $(go.TextBlock, new go.Binding("text", "text"), {
          row: 1,
          column: 0,
          columnSpan: 3,
          margin: 5,
          textAlign: "center",
          font: "bold 14px sans-serif",
        }),

        $(
          go.TextBlock, // lateStart
          new go.Binding("text", "", (d) =>
            (d.lateFinish - d.length).toFixed(0)
          ),
          { row: 2, column: 0, margin: 5, textAlign: "center" }
        ),
        $(
          go.TextBlock, // slack
          new go.Binding("text", "", (d) =>
            (d.lateFinish - (d.earlyStart + d.length)).toFixed(0)
          ),
          { row: 2, column: 1, margin: 5, textAlign: "center" }
        ),
        $(
          go.TextBlock, // lateFinish
          new go.Binding("text", "lateFinish"),
          { row: 2, column: 2, margin: 5, textAlign: "center" }
        )
      ) // end Table Panel
    ); // end Node

    // The link data object does not have direct access to both nodes
    // (although it does have references to their keys: .from and .to).
    // This conversion function gets the GraphObject that was data-bound as the second argument.
    // From that we can get the containing Link, and then the Link.fromNode or .toNode,
    // and then its node data, which has the ".critical" property we need.
    //
    // But note that if we were to dynamically change the ".critical" property on a node data,
    // calling myDiagram.model.updateTargetBindings(nodedata) would only update the color
    // of the nodes.  It would be insufficient to change the appearance of any Links.
    function linkColorConverter(linkdata, elt) {
      var link = elt.part;
      if (!link) return blue;
      var f = link.fromNode;
      if (!f || !f.data || !f.data.critical) return blue;
      var t = link.toNode;
      if (!t || !t.data || !t.data.critical) return blue;
      return pink; // when both Link.fromNode.data.critical and Link.toNode.data.critical
    }

    // The color of a link (including its arrowhead) is red only when both
    // connected nodes have data that is ".critical"; otherwise it is blue.
    // This is computed by the binding converter function.
    myDiagram.linkTemplate = $(
      go.Link,
      { toShortLength: 6, toEndSegmentLength: 20 },
      $(
        go.Shape,
        { strokeWidth: 4 },
        new go.Binding("stroke", "", linkColorConverter)
      ),
      $(
        go.Shape, // arrowhead
        { toArrow: "Triangle", stroke: null, scale: 1.5 },
        new go.Binding("fill", "", linkColorConverter)
      )
    );

    // here's the data defining the graph
    var nodeDataArray = props.keys.goNodes;
    var linkDataArray = props.keys.goLinks;

    myDiagram.model = new go.GraphLinksModel(nodeDataArray, linkDataArray);

    // create an unbound Part that acts as a "legend" for the diagram
    myDiagram.add(
      $(
        go.Node,
        "Auto",
        $(
          go.Shape,
          "Rectangle", // the border
          { fill: bluefill }
        ),
        $(
          go.Panel,
          "Table",
          $(go.RowColumnDefinition, { column: 1, separatorStroke: "black" }),
          $(go.RowColumnDefinition, { column: 2, separatorStroke: "black" }),
          $(go.RowColumnDefinition, {
            row: 1,
            separatorStroke: "black",
            background: bluefill,
            coversSeparators: true,
          }),
          $(go.RowColumnDefinition, { row: 2, separatorStroke: "black" }),
          $(go.TextBlock, "Early Start", {
            row: 0,
            column: 0,
            margin: 5,
            textAlign: "center",
          }),
          $(go.TextBlock, "Duration", {
            row: 0,
            column: 1,
            margin: 5,
            textAlign: "center",
          }),
          $(go.TextBlock, "Early Finish", {
            row: 0,
            column: 2,
            margin: 5,
            textAlign: "center",
          }),

          $(go.TextBlock, "Activity Name", {
            row: 1,
            column: 0,
            columnSpan: 3,
            margin: 5,
            textAlign: "center",
            font: "bold 14px sans-serif",
          }),

          $(go.TextBlock, "Late Start", {
            row: 2,
            column: 0,
            margin: 5,
            textAlign: "center",
          }),
          $(go.TextBlock, "Float", {
            row: 2,
            column: 1,
            margin: 5,
            textAlign: "center",
          }),
          $(go.TextBlock, "Late Finish", {
            row: 2,
            column: 2,
            margin: 5,
            textAlign: "center",
          })
        ) // end Table Panel
      )
    );

    return myDiagram;
  }

  let caFilled = props.inputs.ca.indexOf(true) !== -1 ? true : false;

  let cpOne = "";
  props.inputs.cpOne.forEach((value, index) => {
    cpOne += value ? props.params.N[index] + "-" : "";
  });
  cpOne = cpOne.length > 0 ? cpOne.substring(0, cpOne.length - 1) : cpOne;

  let cpOneIndex = props.keys.cps.indexOf(cpOne);

  return (
    <div>
      <section className="flexSec">
        <PageHeader
          title={"Critical Path Method"}
          description={"Project data for the CPM exercise"}
        />
      </section>
      <section className="newSec ">
        <table className="table textWhite">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Activity</th>
              <th scope="col">Duration</th>
              <th scope="col">Predecessors</th>
            </tr>
          </thead>
          <tbody>
            {props.params.N.map((job, index) => {
              return (
                <tr key={("cpmDataTable" + job + index).toString()}>
                  <th scope="row">{index + 1}</th>
                  <td>{job}</td>
                  <td>{props.proj.D[index]}</td>
                  <td>{props.proj.pStr[index]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>

      <section className="newSec white-sec">
        <h2 className="secTitle">CPM Solutions</h2>
        <h2 className="result-grade-text">
          {"Your grade: " + props.grade + " out of 20"}
        </h2>

        <div className="text-align-left big-mt">
          <h5>
            Question 1: Perform CPM Calculations, and fill in the table below
            (12 points out of 20).
          </h5>
        </div>
        <table className="table mt-2">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Activity</th>
              <th scope="col">EST (2/12)</th>
              <th scope="col">EFT (2/12)</th>
              <th scope="col">LST (2/12)</th>
              <th scope="col">LFT (3/12)</th>
              <th scope="col">Floats (3/12)</th>
            </tr>
          </thead>
          <tbody>
            {props.params.N.map((job, index) => {
              return (
                <tr key={("cpmSolTable" + job + index).toString()}>
                  <th scope="row">{index + 1}</th>
                  <td>{job}</td>
                  <ResultTD
                    inputVal={props.inputs.est[index]}
                    keyVal={props.keys.es[index]}
                    cSpan={1}
                  />
                  <ResultTD
                    inputVal={props.inputs.eft[index]}
                    keyVal={props.keys.es[index] + props.proj.D[index]}
                    cSpan={1}
                  />
                  <ResultTD
                    inputVal={props.inputs.lst[index]}
                    keyVal={props.keys.lf[index] - props.proj.D[index]}
                    cSpan={1}
                  />
                  <ResultTD
                    inputVal={props.inputs.lft[index]}
                    keyVal={props.keys.lf[index]}
                    cSpan={1}
                  />
                  <ResultTD
                    inputVal={props.inputs.f[index]}
                    keyVal={
                      props.keys.lf[index] -
                      props.proj.D[index] -
                      props.keys.es[index]
                    }
                    cSpan={1}
                  />
                </tr>
              );
            })}
          </tbody>
        </table>

        <table className="table  big-mt">
          <thead>
            <tr>
              <th scope="col">
                Q2. Project Duration (=max(EFT) or max(LFT) - 1 point out of 20)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <ResultTD
                inputVal={props.inputs.duration}
                keyVal={props.keys.makespan}
                cSpan={1}
              />
            </tr>
          </tbody>
        </table>

        <table className="table  big-mt">
          <thead>
            <tr>
              <th scope="col">
                Q3. How to identify a critical path (2 point out of 20 -
                Negative marking)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <ResultTD
                inputVal={
                  props.params.cpiOptions[props.inputs[props.params.cpiName]]
                }
                keyVal={props.params.cpiOptions[props.params.cpiSol]}
                cSpan={1}
              />
            </tr>
          </tbody>
        </table>

        <table className="table  big-mt">
          <thead>
            <tr>
              <th scope="col" colSpan="3">
                Q4. The number of critical paths (1 point out of 20 - Negative
                marking)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <ResultTD
                inputVal={props.inputs.cps}
                keyVal={props.keys.cpNr}
                cSpan={3}
              />
            </tr>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Critical Path</th>
              <th scope="col">Cumulative Duration</th>
            </tr>
            {props.keys.cpStr.map((cp, index) => {
              return (
                <tr key={"cps" + cp + index}>
                  <th scope="row">{index + 1}</th>
                  <td>{props.keys.cpStr[index]}</td>
                  <td>{props.keys.makespan}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <table className="table  big-mt">
          <thead>
            <tr>
              <th scope="col">Q5. A critical path (2 point out of 20) </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <ResultTD
                inputVal={cpOne}
                keyVal={
                  cpOneIndex !== -1
                    ? props.keys.cpStr[cpOneIndex]
                    : props.keys.cpStr[0]
                }
                cSpan={1}
              />
            </tr>
          </tbody>
        </table>

        <table className="table  big-mt">
          <thead>
            <tr>
              <th scope="col" colSpan={props.params.n + 1}>
                Q6. All critical activities (2 point out of 20 - only selections
                graded - Negative marking)
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="col" className="text-align-right">
                Activity:
              </th>
              {props.params.N.map((job) => {
                return <td key={("N" + job).toString()}>{job}</td>;
              })}
            </tr>
            <tr>
              <th scope="col" className="text-align-right">
                Critical:
              </th>
              {props.inputs.ca.map((value, index) => {
                return (
                  <ResultTD
                    key={("CA" + value + index).toString()}
                    inputVal={caFilled ? (value ? "Yes" : "No") : ""}
                    keyVal={props.keys.ca[index] ? "Yes" : "No"}
                    cSpan={1}
                  />
                );
              })}
            </tr>
          </tbody>
        </table>
      </section>

      <ReactDiagram
        initDiagram={initDiagram}
        divClassName="diagram-component"
      />
    </div>
  );
}

export default CpmVis;
