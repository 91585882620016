import { useEffect, useState } from "react";
import {
  Header,
  Footer,
  Q1,
  Qmc1,
  QmcAll,
  SubmitModal,
  FullscreenLoading,
  PageHeader,
} from "../../components";
import { reInt } from "../../util/regExpModels";

function CpmQuest(props) {
  const [data, setServerData] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "user/proj", {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => setServerData(data));
  }, []);

  const [inputs, setInputs] = useState({
    est: [],
    eft: [],
    lst: [],
    lft: [],
    f: [],
    cpOne: [],
    ca: [],
  });

  useEffect(() => {
    if (data != null) {
      function inputResize(name, initialValue) {
        setInputs((values) => {
          let prop = values[name];
          prop.length = data.params.n;
          prop.fill(initialValue);
          return { ...values, [name]: prop };
        });
      }
      inputResize("est", "");
      inputResize("eft", "");
      inputResize("lst", "");
      inputResize("lft", "");
      inputResize("f", "");
      inputResize("cpOne", false);
      inputResize("ca", false);
    }
  }, [data]);

  const arrayChange = (event) => {
    const target = event.target;
    const [name, index] = event.target.name.split("-");
    const value = target.type === "checkbox" ? target.checked : target.value;
    if (target.type !== "checkbox") {
      if (!reInt.test(value.toString())) {
        return;
      }
    }
    setInputs((values) => {
      const prop = values[name];
      prop[index] = value;
      return { ...values, [name]: prop };
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (!reInt.test(value.toString())) {
      return;
    }
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "user/sub", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ inputs: inputs, index: props.exerNr }),
    })
      .then((response) => response.json())
      .then((r) => props.updateSeverData(r.data));

    event.preventDefault();
  };

  if (data != null) {
    return (
      <div>
        <Header />
        <section className="flexSec">
          <PageHeader
            title="Critical Path Method"
            description={"Project data for the CPM exercise"}
          />
        </section>
        <section className="newSec">
          <div className="">
            <table className="table textWhite">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Activity</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Predecessors</th>
                </tr>
              </thead>
              <tbody>
                {data.params.N.map((job, index) => {
                  return (
                    <tr key={("cpmDataTable" + job + index).toString()}>
                      <th scope="row">{index + 1}</th>
                      <td>{job}</td>
                      <td>{data.proj.D[index]}</td>
                      <td>{data.proj.pStr[index]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </section>

        <section className="newSec white-sec">
          <h2 className="secTitle">CPM Questions</h2>
          <div className="big-mt">
            <form
              autocomplete="off"
              onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
              onWheel={(e) => e.target.blur()}
            >
              <div className="text-align-left">
                <h5>
                  Question 1: Perform CPM Calculations, and fill in the table
                  below (12 points out of 20).
                </h5>
              </div>
              <table className="table  mt-4">
                <thead>
                  <tr>
                    <th scope="col">Activity</th>
                    <th scope="col">EST (2/12)</th>
                    <th scope="col">EFT (2/12)</th>
                    <th scope="col">LST (2/12)</th>
                    <th scope="col">LFT (3/12)</th>
                    <th scope="col">Float (3/12)</th>
                  </tr>
                </thead>
                <tbody>
                  {data.params.N.map((job, index) => {
                    return (
                      <tr key={("cpmInputTR" + job + index).toString()}>
                        <th scope="row">{index + 1 + ". " + job}</th>
                        <td>
                          <input
                            type="text"
                            name={`est-${index}`}
                            value={inputs.est[index] || ""}
                            onChange={arrayChange}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name={`eft-${index}`}
                            value={inputs.eft[index] || ""}
                            onChange={arrayChange}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name={`lst-${index}`}
                            value={inputs.lst[index] || ""}
                            onChange={arrayChange}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name={`lft-${index}`}
                            value={inputs.lft[index] || ""}
                            onChange={arrayChange}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name={`f-${index}`}
                            value={inputs.f[index] || ""}
                            onChange={arrayChange}
                            className="form-control"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Q1
                classes="big-mt"
                qNr={2}
                question="What is the total duration of the project? (1 point out of 20)"
                name="duration"
                value={inputs["duration"]}
                handleFunction={handleChange}
                placeholder="Project Duration"
              />

              <Qmc1
                keyName="mcqCpiMapKey"
                classes="big-mt"
                qNr={3}
                question={
                  data.params.cpiQuestion +
                  " (2 points out of 20 - Negative marking)"
                }
                options={data.params.cpiOptions}
                name={data.params.cpiName}
                handleFunction={handleChange}
              />

              <Q1
                classes="big-mt"
                qNr={4}
                question="How many critical paths exist in the project? (1 point out of 20 - Negative marking)"
                name="cps"
                value={inputs["cps"]}
                handleFunction={handleChange}
                placeholder="Number of Critical Paths"
              />

              <QmcAll
                keyName="mcqCp1MapKey"
                classes="big-mt"
                qNr={5}
                question={
                  "Select one critical path of your choice (2 points out of 20)."
                }
                options={data.params.N}
                name="cpOne"
                checked={inputs.cpOne}
                handleFunction={arrayChange}
              />

              <QmcAll
                keyName="mcqCaMapKey"
                classes="big-mt"
                qNr={6}
                question={
                  "Select all the critical activities (2 points out of 20 - only selections graded - Negative marking)."
                }
                options={data.params.N}
                name="ca"
                checked={inputs.ca}
                handleFunction={arrayChange}
              />

              <div className="big-mt">
                <SubmitModal
                  btnText="Submit"
                  classes=""
                  variant="success"
                  title="Ready to submit your solutions?"
                  details="The submission is irreversivble. You only have one attempt."
                  handleSubmit={handleSubmit}
                />
              </div>
            </form>
          </div>
        </section>
        <Footer />
      </div>
    );
  } else {
    return <FullscreenLoading />;
  }
}

export default CpmQuest;
