import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Footer, Header, PageHeader } from "../../components";
import { useUserContextState } from "../../context/userContext";
import { useLogin } from "../../hooks/auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

function Login({ type }) {
  const userContext = useUserContextState();
  const navigate = useNavigate();

  const location = useLocation();

  let from = location.state?.from?.pathname || `/${type}`;

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email field can not be empty")
      .email("Please enter a valid Email address"),
    password: Yup.string()
      .required("Password field can not be empty")
      .min(8, "Password must be at least 8 characters long"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm(formOptions);

  const login = useLogin(type);

  const onSubmit = (data) => {
    if (!userContext.loggedIn || userContext.data.type !== type) {
      login(data.email, data.password)
        .then(() => {
          navigate(from);
        })
        .catch((error) => {
          setError("server", { message: error.message });
        });
    }
  };

  if (userContext.loggedIn && userContext?.data?.type === type) {
    return <Navigate to={from} />;
  } else {
    return (
      <div>
        <Header />
        <section id="login-sec" className="projects">
          <PageHeader
            title={(type === "user" ? "Student " : "Teacher ") + "Login"}
            description="Enter your email and password"
          />
        </section>

        <section className=" mb-5">
          <div className="newSec">
            <section>
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center h-100">
                  <div className="col-md-8 col-lg-7 col-xl-6">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/login.svg`}
                      className="img-fluid imgUnselectable"
                      alt="login"
                    />
                  </div>
                  <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
                    <form
                      action="/login"
                      method="POST"
                      className="form"
                      noValidate
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-floating mb-4">
                        <input
                          type="email"
                          name="email"
                          placeholder="name@email.com"
                          id="loginEmail"
                          className={`form-control form-control-lg ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          {...register("email")}
                        />
                        <label className="form-label" htmlFor="loginEmail">
                          Email address
                        </label>
                        <div className="invalid-feedback">
                          {errors.email?.message}
                        </div>
                      </div>

                      <div className="form-floating mb-4">
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          id="loginPassword"
                          className={`form-control form-control-lg ${
                            errors.password ? "is-invalid" : ""
                          }`}
                          {...register("password")}
                        />
                        <label className="form-label" htmlFor="loginPassword">
                          Password
                        </label>
                        <div className="invalid-feedback">
                          {errors.password?.message}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-lg col-12 mb-4"
                      >
                        Sign in
                      </button>

                      {type === "user" ? (
                        <div>
                          <p className="small mt-2 mb-0">
                            Forgot password?{" "}
                            <Link to="/user/forgotpassword" className="">
                              Reset password
                            </Link>
                          </p>
                          <br />
                          {/* <p className="small mt-2 mb-0">Don't have an account? <Link> to="/register" className="">Register</Link></p> */}
                        </div>
                      ) : (
                        <div>
                          <p className="small mt-2 mb-0">
                            Forgot password?{" "}
                            <Link to="#!" className="">
                              Reset password
                            </Link>
                          </p>
                          <br />
                          {/* <p className="small mt-2 mb-0">Don't have an account? <Link> to="/register" className="">Register</Link></p> */}
                        </div>
                      )}

                      {errors.server && (
                        <div className="alert alert-danger">
                          {errors.server?.message}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default Login;
