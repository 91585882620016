import { useNavigate, useParams, Link } from "react-router-dom";
import {
  Header,
  Footer,
  FullscreenLoading,
  CSVReader,
  PageHeader,
} from "../../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { lowerCase, startCase, toLower, upperCase } from "lodash-es";

const isEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value); // or use your own

function GroupAddUsers() {
  const navigate = useNavigate();

  const { id } = useParams();

  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "prof/group/" + id, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.success) {
          setData(r.data);
        } else {
          console.log(r.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const formSchema = Yup.object().shape({
    emails: Yup.string()
      .required()
      .test(
        "emails",
        "Invalid email addresses",
        (value) => value && value.split(";").every(isEmail)
      ),
    fnames: Yup.string().required("First names field can not be empty"),
    lnames: Yup.string().required("Last names field can not be empty"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  const handleCSVData = (csv) => {
    let fNameIndex = -1;
    let lNameIndex = -1;
    let emailIndex = -1;
    for (let i = 0; i < csv.data[0].length; i++) {
      const csvHeader = lowerCase(csv.data[0][i]); // csv.data[0][i].normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      if (
        /pr.nom/.test(csvHeader) ||
        csvHeader === "first name" ||
        csvHeader === "name"
      ) {
        fNameIndex = i;
      } else if (
        csvHeader === "nom" ||
        csvHeader === "last name" ||
        csvHeader === "surname" ||
        csvHeader === "family name"
      ) {
        lNameIndex = i;
      } else if (
        csvHeader === "mail" ||
        csvHeader === "mail address" ||
        csvHeader === "email" ||
        csvHeader === "email address"
      ) {
        emailIndex = i;
      }
    }
    if (fNameIndex === -1) {
      alert(
        "CSV file missing a column: prenom or prénom or first name or name"
      );
      return;
    } else if (lNameIndex === -1) {
      alert(
        "CSV file missing a column: nom or last name or surname or family name"
      );
      return;
    } else if (emailIndex === -1) {
      alert(
        "CSV file missing a column: mail or mail address or email or email adress"
      );
      return;
    } else {
      let csvFNames = "";
      let csvLNames = "";
      let csvEmails = "";
      let csvCounter = 0;
      for (let i = 1; i < csv.data.length; i++) {
        if (
          csv.data[i].length >= fNameIndex &&
          csv.data[i].length >= lNameIndex &&
          csv.data[i].length >= emailIndex
        ) {
          csvFNames += startCase(toLower(csv.data[i][fNameIndex])) + ";";
          csvLNames += upperCase(csv.data[i][lNameIndex]) + ";";
          csvEmails += csv.data[i][emailIndex] + ";";
          csvCounter += 1;
        }
      }
      csvFNames =
        csvFNames.length > 0
          ? csvFNames.substring(0, csvFNames.length - 1)
          : "";
      csvLNames =
        csvLNames.length > 0
          ? csvLNames.substring(0, csvLNames.length - 1)
          : "";
      csvEmails =
        csvEmails.length > 0
          ? csvEmails.substring(0, csvEmails.length - 1)
          : "";
      setValue("fnames", csvFNames);
      setValue("lnames", csvLNames);
      setValue("emails", csvEmails);

      alert(
        csvCounter + " rows imported. Click on Create Accounts to register."
      );
    }
  };

  const onSubmit = (inputs) => {
    const emails = inputs.emails.split(";");
    const fnames = inputs.fnames.split(";");
    const lnames = inputs.lnames.split(";");

    if (!(emails.length === fnames.length && fnames.length === lnames.length)) {
      setError("global", {
        message:
          "Mismatch in entries: inequal number of emails, first names, and/or last names",
      });
    } else {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}prof/group/${id}/users`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(inputs),
      })
        .then((response) => response.json())
        .then((r) => {
          if (r.success) {
            navigate("/prof/groupusers/" + id, {
              state: { users: r.data },
            });
          }
        });
    }
  };

  if (data) {
    return (
      <div>
        <Header />
        <section className="flexSec">
          <PageHeader
            title={data.name}
            description={"Add students manually or via CSV files"}
          />
        </section>
        <section className="newSec ">
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) =>
              (e.key === "Enter" ||
                e.key === "ArrowUp" ||
                e.key === "ArrowDown") &&
              e.preventDefault()
            }
            onWheel={(e) => e.target.blur()}
          >
            <CSVReader classes={"big-mt"} dataHandle={handleCSVData} />
            <table className="table textWhite mt-5">
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <b>Emails</b> <br /> (Serperate with ; No Spaces)
                  </td>
                  <td>
                    <textarea
                      name="emails"
                      placeholder="Emails"
                      {...register("emails")}
                      className={`form-control ${
                        errors.emails ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.emails?.message}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <b>First Names</b> <br /> (Serperate with ;)
                  </td>
                  <td>
                    <textarea
                      name="fnames"
                      placeholder="First Names"
                      className={`form-control ${
                        errors.fnames ? "is-invalid" : ""
                      }`}
                      {...register("fnames")}
                    />
                    <div className="invalid-feedback">
                      {errors.fnames?.message}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Last Names</b> <br />
                    (Serperate with ;)
                  </td>
                  <td>
                    <textarea
                      name="lnames"
                      placeholder="Last Names"
                      {...register("lnames")}
                      className={`form-control ${
                        errors.lnames ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.lnames?.message}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            {errors.global && (
              <div className="alert alert-danger">{errors.global?.message}</div>
            )}

            <Link
              to={"/prof/groupusers/" + id}
              className="btn btn-lg btn-outline-light mt-5 mx-5"
            >
              Cancel
            </Link>

            <input
              className="btn btn-lg btn-outline-success mt-5"
              type="submit"
              value={"Create Accounts"}
            />
          </form>
        </section>
        <Footer />
      </div>
    );
  } else {
    return <FullscreenLoading />;
  }
}

export default GroupAddUsers;
