import { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  Header,
  Footer,
  SubmitModal,
  FullscreenLoading,
  PageHeader,
} from "../../components";
import { CSVLink } from "react-csv";

function GroupUsers(props) {
  const { id } = useParams();

  const [data, setData] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location.state?.data) {
      setData(location.state.data);
    }
  }, [location.state?.data]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}prof/group/${id}/users`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.success) {
          setData(r.data);
        } else {
          console.log(r.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const handleDelete = (userid) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "prof/user/" + userid, {
      method: "DELETE",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.success) {
          setData(r.data);
        } else {
          console.log(r.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const navigate = useNavigate();
  // const handleGrade = (userid) => {
  //   fetch(process.env.REACT_APP_API_ENDPOINT + "prof/user/" + userid, {
  //     method: "PATCH",
  //     credentials: "include",
  //   })
  //     .then((response) => response.json())
  //     .then((r) => {
  //       if (r.success) {
  //         setData(r.data);
  //         navigate("/prof/groupusers/" + id, {
  //           state: { data: r.data },
  //         });
  //       } else {
  //         console.log(r.msg);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const headers = [
    { label: "First Name", key: "fname" },
    { label: "Last Name", key: "lname" },
    { label: "Email", key: "email" },
    { label: "CPM Grade", key: "grades[0]" },
    { label: "CPM Date", key: "subDates[0]" },
    { label: "RL Grade", key: "grades[1]" },
    { label: "RL Date", key: "subDates[1]" },
    { label: "PERT Grade", key: "grades[2]" },
    { label: "PERT Date", key: "subDates[2]" },
    { label: "MSP Grade", key: "grades[3]" },
    { label: "MSP Date", key: "subDates[3]" },
  ];

  if (data) {
    return (
      <div>
        <Header />
        <section className="flexSec">
          <PageHeader
            title={data.groupName}
            description={"Current registered students"}
          />
        </section>
        <section className="newSec ">
          <table className="table textWhite">
            <thead>
              <tr>
                <th scope="col" rowSpan={2}>
                  #
                </th>
                <th scope="col" rowSpan={2} className="text-align-left">
                  Name
                </th>
                <th scope="col" colSpan={2}>
                  CPM
                </th>
                <th scope="col" colSpan={2}>
                  RL
                </th>
                <th scope="col" colSpan={2}>
                  PERT
                </th>
                <th scope="col" colSpan={2}>
                  MSP
                </th>
                <th scope="col" rowSpan={2}>
                  Delete
                </th>
                {/* <th scope="col" rowSpan={2}>
                  Re-grade
                </th> */}
              </tr>
              <tr>
                <th scope="col">Grade</th>
                <th scope="col">
                  <i className="fa-regular fa-clock"></i>
                </th>
                <th scope="col">Grade</th>
                <th scope="col">
                  <i className="fa-regular fa-clock"></i>
                </th>
                <th scope="col">Grade</th>
                <th scope="col">
                  <i className="fa-regular fa-clock"></i>
                </th>
                <th scope="col">Grade</th>
                <th scope="col">
                  <i className="fa-regular fa-clock"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.users.map((user, index) => {
                return (
                  <tr
                    key={(
                      "cpmDataTable" +
                      user.fname +
                      user.lname +
                      index
                    ).toString()}
                  >
                    <th scope="row">{index + 1}</th>
                    <td className="text-align-left">
                      <Link to={`/prof/detaileduser/${user._id}`}>
                        {user.lname + " " + user.fname}
                      </Link>
                      <br />
                      {user.email}
                    </td>
                    <td>
                      {user.subState[0] === true ? user.grades[0] : "---"}
                    </td>
                    <td>
                      {user.subState[0] === true ? user.subDates[0] : "---"}
                    </td>
                    <td>
                      {user.subState[1] === true ? user.grades[1] : "---"}
                    </td>
                    <td>
                      {user.subState[1] === true ? user.subDates[1] : "---"}
                    </td>
                    <td>
                      {user.subState[2] === true ? user.grades[2] : "---"}
                    </td>
                    <td>
                      {user.subState[2] === true ? user.subDates[2] : "---"}
                    </td>
                    <td>
                      {user.subState[3] === true ? user.grades[3] : "---"}
                    </td>
                    <td>
                      {user.subState[3] === true ? user.subDates[3] : "---"}
                    </td>
                    <td>
                      <SubmitModal
                        btnText={
                          <i className="fa-solid fa-trash text-danger btn-delete-opacity"></i>
                        }
                        classes=""
                        variant=""
                        title="Are you sure?"
                        details="Deleting a user is irreversible."
                        handleSubmit={() => handleDelete(user._id)}
                      />
                    </td>
                    {/* <td>
                      <button
                        className="btn btn-lg "
                        onClick={() => handleGrade(user._id)}
                      >
                        <i className="fa-solid fa-file-circle-check"></i>
                      </button>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p className="text-align-left">* Grades out of 20</p>

          <Link to="/prof" className="btn btn-lg btn-outline-light my-5 mx-5">
            <i className="fa-solid fa-circle-left"></i> Back
          </Link>

          <Link
            to={"/prof/addusers/" + id}
            className="btn btn-lg btn-outline-success my-5"
          >
            <i className="fa-solid fa-plus"></i> Add Students
          </Link>
          <CSVLink
            className="btn btn-lg btn-outline-primary my-5 mx-5"
            data={data.users}
            headers={headers}
            filename={data.groupName + ".csv"}
          >
            <i className="fa-solid fa-file-arrow-down"></i> Download Grades
          </CSVLink>
        </section>
        <Footer />
      </div>
    );
  } else {
    return <FullscreenLoading />;
  }
}

export default GroupUsers;
