import { useEffect, useState } from "react";
import {
  Header,
  Footer,
  Q1,
  SubmitModal,
  FullscreenLoading,
  PageHeader,
} from "../../components";
import { reInt } from "../../util/regExpModels";

function MspQuest(props) {
  const [data, setServerData] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "user/proj", {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => setServerData(data));
  }, []);

  const [inputs, setInputs] = useState({
    totalCost: [],
  });

  useEffect(() => {
    if (data != null) {
      function inputResize(name, initialValue) {
        setInputs((values) => {
          let prop = values[name];
          prop.length = data.params.n;
          prop.fill(initialValue);
          return { ...values, [name]: prop };
        });
      }
      inputResize("totalCost", "");
    }
  }, [data]);

  const arrayChange = (event) => {
    const target = event.target;
    const [name, index] = event.target.name.split("-");
    let value = target.type === "checkbox" ? target.checked : target.value;
    if (target.type !== "checkbox") {
      if (!reInt.test(value.toString())) {
        return;
      }
    }
    setInputs((values) => {
      const prop = values[name];
      prop[index] = value;
      return { ...values, [name]: prop };
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    if (!reInt.test(value.toString())) {
      return;
    }
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "user/sub", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ inputs: inputs, index: props.exerNr }),
    })
      .then((response) => response.json())
      .then((r) => props.updateSeverData(r.data));

    event.preventDefault();
  };

  if (data != null) {
    let tInProjBool = new Array(data.params.rMax * data.params.rMax);
    let rtCount = new Array(data.params.rMax);
    for (let r = 0; r < data.params.rMax; r++) {
      rtCount[r] = 0;
      for (let t = 0; t < data.params.rMax; t++) {
        tInProjBool[r * data.params.rMax + t] = false;
      }
    }
    for (let i = 0; i < data.params.n; i++) {
      if (
        !tInProjBool[data.proj.R[i] * data.params.rMax + data.proj.rType[i]]
      ) {
        tInProjBool[
          data.proj.R[i] * data.params.rMax + data.proj.rType[i]
        ] = true;
        rtCount[data.proj.R[i]]++;
      }
    }
    let rPrintBool = new Array(data.params.rMax);
    for (let r = 0; r < data.params.rMax; r++) {
      rPrintBool[r] = -1;
      if (r !== 1) {
        for (let t = 0; t < data.params.rMax; t++) {
          if (data.proj.rUsed[r * data.params.rMax + t]) {
            if (rPrintBool[r] === -1) {
              rPrintBool[r] = t;
            }
          }
        }
      }
    }

    return (
      <div>
        <Header />
        <section className="flexSec">
          <PageHeader
            title={"Microsoft Project"}
            description={"Project data for the MSP exercise"}
          />
        </section>
        <section className="newSec ">
          <table className="table textWhite">
            <thead>
              <tr>
                <th scope="col" rowSpan="2">
                  #
                </th>
                <th scope="col" rowSpan="2">
                  Activity
                </th>
                <th scope="col" rowSpan="2">
                  Duration (days)
                </th>
                <th scope="col" rowSpan="2">
                  Predecessors
                </th>
                <th scope="col" colSpan="3">
                  Resource
                </th>
              </tr>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Total Cost</th>
              </tr>
            </thead>
            <tbody>
              {data.params.N.map((job, index) => {
                return (
                  <tr key={"mspDataTable" + job + index}>
                    <th scope="row">{index + 1}</th>
                    <td>{job}</td>
                    <td>{data.proj.D[index]}</td>
                    <td>{data.proj.pStr[index]}</td>
                    <td>{data.params.resNames[data.proj.R[index]]}</td>
                    {data.proj.R[index] !== 1 ? (
                      <td>
                        {data.params.resTypeNames[data.proj.rType[index]]}
                      </td>
                    ) : (
                      <td>
                        <b>?</b>
                      </td>
                    )}
                    {data.proj.R[index] === 1 ? (
                      <td>
                        {data.proj.D[index] *
                          data.params.hoursPerDay *
                          data.proj.rCost[
                            data.proj.R[index] * data.params.rMax +
                              data.proj.rType[index]
                          ]}
                      </td>
                    ) : (
                      <td>
                        <b>?</b>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <table className="table textWhite big-mt">
            <thead>
              <tr>
                <th scope="col">Resource</th>
                <th scope="col">Type</th>
                <th scope="col">Cost (per hour)</th>
              </tr>
            </thead>
            <tbody>
              {data.params.resNames.map((resource, r) => {
                return (
                  r !== 1 &&
                  data.params.resTypeNames.map((type, t) => {
                    return (
                      data.proj.rUsed[r * data.params.rMax + t] && (
                        <tr key={"resTypeTable" + resource + r + type + t}>
                          {rPrintBool[r] === t && (
                            <td
                              className="vertical-align-center"
                              rowSpan={rtCount[r]}
                            >
                              {data.params.resNames[r]}
                            </td>
                          )}
                          <td>{data.params.resTypeNames[t]}</td>
                          <td>{data.proj.rCost[r * data.params.rMax + t]}</td>
                        </tr>
                      )
                    );
                  })
                );
              })}
            </tbody>
          </table>
        </section>

        <section className="newSec white-sec">
          <h2 className="secTitle">MSP Questions</h2>
          <div className="big-mt">
            <form
              autocomplete="off"
              onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
              onWheel={(e) => e.target.blur()}
            >
              <div className="text-align-left">
                <h5>General Instructions:</h5>
                <p>
                  (i) In the software, enter the salaries of the Technology and
                  Marketing "per hour", and the salaries of Design "per day".
                </p>
                <p>
                  (ii) The software will take care of the unit conversions
                  (e.g., durations per day, salaries per hour).
                </p>
                <p>
                  (iii) The number of working hours per day is not required in
                  your calculations.
                </p>
                <p>
                  (iv) The names of different Design resource types is not
                  important. You can call them DJ, DA, DS, or D1, D2, D3, or ...
                </p>
              </div>
              <div className="text-align-left big-mt">
                <h5>Question 1: MSP Calculations (12 points out of 20).</h5>
              </div>
              <table className="table mt-2">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Activity</th>
                    <th scope="col">Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {data.params.N.map((job, index) => {
                    return (
                      data.proj.R[index] !== 1 && (
                        <tr key={("pertInputTR" + job + index).toString()}>
                          <th scope="row">{index + 1}</th>
                          <th scope="row">{job}</th>
                          <td>
                            <input
                              type="text"
                              name={`totalCost-${index}`}
                              value={inputs.totalCost[index] || ""}
                              onChange={arrayChange}
                              className="form-control"
                            />
                          </td>
                        </tr>
                      )
                    );
                  })}
                </tbody>
              </table>

              <Q1
                classes="big-mt"
                qNr={2}
                question={
                  "How many salary types exist in the " +
                  data.params.resNames[1] +
                  " department? (4 points out of 20 - Negative marking)"
                }
                name="designTypeCount"
                value={inputs["designTypeCount"]}
                handleFunction={handleChange}
                placeholder="Design Salary Types"
              />

              <Q1
                classes="big-mt"
                qNr={3}
                question="What is the total Budget of the project? (4 points out of 20)"
                name="totalBudget"
                value={inputs["totalBudget"]}
                handleFunction={handleChange}
                placeholder="Total Budget"
              />

              <div className="big-mt">
                <SubmitModal
                  btnText="Submit"
                  classes=""
                  variant="success"
                  title="Ready to submit your solutions?"
                  details="The submission is irreversivble. You only have one attempt."
                  handleSubmit={handleSubmit}
                />
              </div>
            </form>
          </div>
        </section>
        <Footer />
      </div>
    );
  } else {
    return <FullscreenLoading />;
  }
}

export default MspQuest;
