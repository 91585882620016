import "./FullscreenLoading.css";

const FullscreenLoading = () => (
  <div className="loadingContainer">
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default FullscreenLoading;
