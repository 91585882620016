import { useEffect, useState } from "react";
import {
  Header,
  Footer,
  Q1,
  SubmitModal,
  FullscreenLoading,
  PageHeader,
} from "../../components";
import { reDotBegin, reFloatTwoDecimals } from "../../util/regExpModels";

function PertQuest(props) {
  const [data, setServerData] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "user/proj", {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => setServerData(data));
  }, []);

  const [inputs, setInputs] = useState({
    min: [],
    ml: [],
    max: [],
    mean: [],
    variance: [],
  });

  useEffect(() => {
    if (data != null) {
      function inputResize(name, initialValue) {
        setInputs((values) => {
          let prop = values[name];
          prop.length = data.params.n;
          prop.fill(initialValue);
          return { ...values, [name]: prop };
        });
      }
      inputResize("min", "");
      inputResize("ml", "");
      inputResize("max", "");
      inputResize("mean", "");
      inputResize("variance", "");
    }
  }, [data]);

  const arrayChange = (event) => {
    const target = event.target;
    const [name, index] = event.target.name.split("-");
    let value = target.type === "checkbox" ? target.checked : target.value;
    if (target.type !== "checkbox") {
      if (reDotBegin.test(value.toString())) {
        value = "0" + value.toString();
      }
      if (!reFloatTwoDecimals.test(value.toString())) {
        return;
      }
    }
    setInputs((values) => {
      const prop = values[name];
      prop[index] = value;
      return { ...values, [name]: prop };
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (reDotBegin.test(value.toString())) {
      value = "0" + value.toString();
    }
    if (!reFloatTwoDecimals.test(value.toString())) {
      return;
    }

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "user/sub", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ inputs: inputs, index: props.exerNr }),
    })
      .then((response) => response.json())
      .then((r) => props.updateSeverData(r.data));

    event.preventDefault();
  };

  if (data != null) {
    return (
      <div>
        <Header />
        <section className="flexSec">
          <PageHeader
            title={"PERT Method"}
            description={"Project data for the PERT exercise"}
          />
        </section>
        <section className="newSec ">
          <table className="table textWhite">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Activity</th>
                <th scope="col">Duration</th>
                <th scope="col">Predecessors</th>
              </tr>
            </thead>
            <tbody>
              {data.params.N.map((job, index) => {
                return (
                  <tr key={("cpmDataTable" + job + index).toString()}>
                    <th scope="row">{index + 1}</th>
                    <td>{job}</td>
                    <td>{data.proj.D[index]}</td>
                    <td>{data.proj.pStr[index]}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <table className="table textWhite big-mt">
            <thead>
              <tr>
                <th scope="col">Assigned Critical Path</th>
                <th scope="col">Min-Max Deviation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data.proj.pertCP}</td>
                <td>{data.proj.pertDev}%</td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="newSec white-sec">
          <h2 className="secTitle">PERT Questions</h2>
          <div className="big-mt">
            <form
              autocomplete="off"
              onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
              onWheel={(e) => e.target.blur()}
            >
              <div className="text-align-left">
                <h5>General Instructions:</h5>
                <p>
                  (i) Round to two decimal places: 1.234 becomes 1.23, and 1.235
                  becomes 1.24.
                </p>
                <p>
                  (ii) Use point/dot (".") as the decimal serparator: 1.23 is
                  correct, but 1,23 is incorrect.
                </p>
                <p>
                  (iii) Rounding issues up to 0.01 are tolerated in grading: If
                  the correct value is 1.23, then inputs 1.22, 1.23, or 1.24
                  count as correct (but highlighted in red).
                </p>
              </div>
              <div className="text-align-left big-mt">
                <h5>
                  Question 1: PERT Calculations. Round to two decimal places (10
                  points out of 20).
                </h5>
                <p>
                  (i) Min is {data.proj.pertDev}% less than the average
                  duration, and Max is {data.proj.pertDev}% more. Most Likely is
                  equal to the average.
                </p>
                <p>(ii) Mean = (Min + 4 * MostLikely + Max) / 6</p>
                <p>(iii) StandardDeviation = (Max - Min) / 6</p>
                <p>(iv) Variance = (StandardDeviation)^2</p>
              </div>
              <table className="table  mt-2">
                <thead>
                  <tr>
                    <th scope="col" rowSpan="2">
                      Activity
                    </th>
                    <th scope="col" colSpan="3">
                      Estimated Durations (5/10)
                    </th>
                    <th scope="col" colSpan="2">
                      Distribution (5/10)
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">Min (2/10)</th>
                    <th scope="col">Most Likely (1/10)</th>
                    <th scope="col">Max (2/10)</th>
                    <th scope="col">Mean (1/10)</th>
                    <th scope="col">Variance (4/10)</th>
                  </tr>
                </thead>
                <tbody>
                  {data.params.N.map((job, index) => {
                    return (
                      <tr key={("pertInputTR" + job + index).toString()}>
                        <th scope="row">{index + 1 + ". " + job}</th>
                        <td>
                          <input
                            type="text"
                            name={`min-${index}`}
                            value={inputs.min[index] || ""}
                            onChange={arrayChange}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name={`ml-${index}`}
                            value={inputs.ml[index] || ""}
                            onChange={arrayChange}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name={`max-${index}`}
                            value={inputs.max[index] || ""}
                            onChange={arrayChange}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name={`mean-${index}`}
                            value={inputs.mean[index] || ""}
                            onChange={arrayChange}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name={`variance-${index}`}
                            value={inputs.variance[index] || ""}
                            onChange={arrayChange}
                            className="form-control"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Q1
                classes="big-mt"
                qNr={2}
                question="What is the total Mean of the Critical Path? (2 points out of 20)"
                name="totalMean"
                value={inputs["totalMean"]}
                handleFunction={handleChange}
                placeholder="Total Mean"
              />
              <div className="text-align-left mt-2">
                <p>*Critical path: {data.proj.pertCP}.</p>
              </div>

              <Q1
                classes="big-mt"
                qNr={3}
                question="What is the total Variance of the Critical Path? Round to two decimal places (3 points out of 20)."
                name="totalVariance"
                value={inputs["totalVariance"]}
                handleFunction={handleChange}
                placeholder="Total Variance"
              />
              <div className="text-align-left mt-2">
                <p>*Critical path: {data.proj.pertCP}.</p>
              </div>

              <Q1
                classes="big-mt"
                qNr={4}
                question={
                  "What is the probability [0-1] of finishing the project by " +
                  data.proj.pertDur +
                  " Days? Round to two decimal places (2.5 points out of 20)."
                }
                name="durProb"
                value={inputs["durProb"]}
                handleFunction={handleChange}
                placeholder="Cumulative Probability"
              />
              <div className="text-align-left mt-2">
                <p>
                  *Use "Norm.Dist" function in Excel ("LOI.NORMALE" in French).
                </p>
              </div>

              <Q1
                classes="big-mt"
                qNr={5}
                question={
                  "What is the duration of the P" +
                  data.proj.pertPx +
                  " schedule? Round to two decimal places (2.5 points out of 20)."
                }
                name="pxDur"
                value={inputs["pxDur"]}
                handleFunction={handleChange}
                placeholder="Px Duration"
              />
              <div className="text-align-left mt-2">
                <p>
                  *Use "Norm.Inv" function in Excel ("LOI.NORMALE.INVERSE" in
                  French).
                </p>
              </div>

              <div className="big-mt">
                <SubmitModal
                  btnText="Submit"
                  classes=""
                  variant="success"
                  title="Ready to submit your solutions?"
                  details="The submission is irreversivble. You only have one attempt."
                  handleSubmit={handleSubmit}
                />
              </div>
            </form>
          </div>
        </section>
        <Footer />
      </div>
    );
  } else {
    return <FullscreenLoading />;
  }
}

export default PertQuest;
