function TRProfMC(props) {
  return (
    <tr>
      <th scope="row">{props.title + ":"}</th>
      <td>
        {props.options.map((option, index) => {
          return (
            <div
              className="form-check form-check-inline"
              key={props.keyName + option + index}
            >
              <input
                name={props.name + "-" + index}
                className="form-check-input"
                type="checkbox"
                id={props.name + "-" + index}
                checked={props.checked[index] || ""}
                onChange={props.handleFunction}
              />
              <label
                className="form-check-label"
                htmlFor={props.name + "-" + index}
              >
                {option}
              </label>
            </div>
          );
        })}
      </td>
    </tr>
  );
}

export default TRProfMC;
