import { useEffect, useState } from "react";
import { ExerNotActive, FullscreenLoading } from "../../components";
import RlQuest from "./RlQuest";
import RlSol from "./RlSol";

function Rl() {
  const [data, setSeverData] = useState(null);

  let exerNr = 1;

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "user/state", {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((r) => setSeverData(r.data));
  }, []);

  if (data != null) {
    if (
      data.exerActive[exerNr] === false &&
      data.subState[exerNr] === "false"
    ) {
      return <ExerNotActive exerName={"RL"} />;
    } else if (data.subState[exerNr] === "false") {
      return <RlQuest updateSeverData={setSeverData} exerNr={exerNr} />;
    } else {
      return <RlSol exerNr={exerNr} />;
    }
  } else {
    return <FullscreenLoading />;
  }
}

export default Rl;
