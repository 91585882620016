import { NavLink } from "react-router-dom";

function NavLinksHome(props) {
  return (
    <>
      <NavLink to="/" onClick={() => props.setIsMenuOpen(false)}>
        Home
      </NavLink>
      <NavLink to="/about" onClick={() => props.setIsMenuOpen(false)}>
        About
      </NavLink>
      <NavLink to="/leaderboard" onClick={() => props.setIsMenuOpen(false)}>
        Leaderboard
      </NavLink>
      <NavLink to="/contact" onClick={() => props.setIsMenuOpen(false)}>
        Contact
      </NavLink>
    </>
  );
}

export default NavLinksHome;
