import { useEffect, useState } from "react";
import { Header, Footer, FullscreenLoading } from "../../components";
import { RlVis } from "../../routes";
function RlSol(props) {
  const [data, setServerData] = useState(null);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "user/sol/" + props.exerNr, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((r) => setServerData(r.data));
  }, [props.exerNr]);

  if (data != null) {
    if (data.subState) {
      return (
        <div>
          <Header />
          <RlVis
            proj={data.proj}
            keys={data.keys}
            params={data.params}
            subState={data.subState}
            inputs={data.inputs}
            grade={data.grade}
          />
          <Footer />
        </div>
      );
    } else {
      return <FullscreenLoading />;
    }
  } else {
    return <FullscreenLoading />;
  }
}

export default RlSol;
