import { useEffect, useState } from "react";
import { ExerNotActive, FullscreenLoading } from "../../components";
import CpmQuest from "./CpmQuest";
import CpmSol from "./CpmSol";

function Cpm() {
  const [data, setSeverData] = useState(null);

  let exerNr = 0;

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "user/state", {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((r) => setSeverData(r.data));
  }, []);

  if (data != null) {
    if (
      data.exerActive[exerNr] === false &&
      data.subState[exerNr] === "false"
    ) {
      return <ExerNotActive exerName={"CPM"} />;
    } else if (data.subState[exerNr] === "false") {
      return <CpmQuest updateSeverData={setSeverData} exerNr={exerNr} />;
    } else {
      return <CpmSol exerNr={exerNr} />;
    }
  } else {
    return <FullscreenLoading />;
  }
}

export default Cpm;
