import { useState, useEffect, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import NoMatch from "../NoMatch/NoMatch";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Footer,
  FullscreenLoading,
  Header,
  PageHeader,
} from "../../components";
import { useUserContext } from "../../context/userContext";

const UserSetPassword = ({ type }) => {
  const [, setUserContext] = useUserContext();
  const [searchParams] = useSearchParams();
  const [compState, setCompState] = useState("loading"); // can be "loading", "valid", "invalid"
  const navigate = useNavigate();

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is mandatory")
      .min(8, "Password must be at least 8 characters long"),
    confirmPwd: Yup.string()
      .required("Password is mandatory")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {
    const token = searchParams.get("token");
    const id = searchParams.get("id");
    if (token === "" || id === "") {
      setCompState("invalid");
    } else {
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}user/${type}?token=${token}&id=${id}` // type = "compeletesignup" || "resetpassword"
      )
        .then((response) => {
          if (response.status !== 200) {
            throw new Error("something went wrong");
          }
          return response.json();
        })
        .then((r) => {
          if (r.success) {
            setCompState("valid");
          } else {
            throw new Error("token or id is not valid");
          }
        })
        .catch((error) => {
          setCompState("invalid");
        });
    }
  }, [searchParams, type]);

  const onSubmit = useCallback(
    (data) => {
      fetch(`${process.env.REACT_APP_API_ENDPOINT}user/${type}`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: searchParams.get("id"),
          token: searchParams.get("token"),
          password: data.password,
        }),
      })
        .then((response) => response.json())
        .then((r) => {
          if (r.success) {
            setUserContext((current) => ({
              ...current,
              loggedIn: true,
              data: r.data,
            }));
            console.log(r);
            navigate("/");
          } else {
            throw new Error(r.msg);
          }
        })
        .catch((error) => {
          setError("server", { message: error.message });
        });
    },
    [searchParams, navigate, setError, setUserContext, type]
  );

  if (compState === "valid") {
    return (
      <div>
        <Header />
        <section id="login-sec" className="projects">
          <PageHeader
            title="Set Password"
            description="Enter and confirm your password"
          />
        </section>
        <section className="mb-5">
          <div className="newSec">
            <section>
              <div className="container">
                <div className="row d-flex align-items-center justify-content-center h-100">
                  <div className="col-md-8 col-lg-7 col-xl-6">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/login.svg`}
                      className="img-fluid imgUnselectable"
                      alt="login"
                    />
                  </div>
                  <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
                    <form onSubmit={handleSubmit(onSubmit)} className="form">
                      <div className="form-floating mb-4">
                        <input
                          name="password"
                          type="password"
                          id="password"
                          placeholder="Enter New Password"
                          {...register("password")}
                          className={`form-control form-control-lg ${
                            errors.password ? "is-invalid" : ""
                          }`}
                        />
                        <label className="form-label" htmlFor="password">
                          New Password
                        </label>
                        <div className="invalid-feedback">
                          {errors.password?.message}
                        </div>
                      </div>
                      <div className="form-floating mb-4">
                        <input
                          name="confirmPwd"
                          type="password"
                          id="confirmPwd"
                          placeholder="Confirm New Password"
                          {...register("confirmPwd")}
                          className={`form-control form-control-lg ${
                            errors.confirmPwd ? "is-invalid" : ""
                          }`}
                        />
                        <label className="form-label" htmlFor="confirmPwd">
                          Confirm Password
                        </label>
                        <div className="invalid-feedback">
                          {errors.confirmPwd?.message}
                        </div>
                      </div>
                      <div className="mt-3">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg col-12 mb-4"
                        >
                          Set Password
                        </button>
                      </div>
                      {errors.server && (
                        <div className="alert alert-danger">
                          {errors.server?.message}
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        <Footer />
      </div>
    );
  } else if (compState === "loading") {
    return <FullscreenLoading />;
  } else {
    return <NoMatch />;
  }
};

export default UserSetPassword;
