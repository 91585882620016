import Form from "./Form";
import ContactInfo from "./ContactInfo";
import { Footer, Header, PageHeader } from "../../components";

const personalDetails = {
  name: "Salim Rostami",
  location: "Paris & Lille, France",
  tagline: "I'm a Professor",
  email: "s[dot]rostami[at]ieseg[dot]fr",
  affiliation: "IESEG School of Management",
  brand: "Professor of Operations Management, PhD in Operations Research.",
  intro:
    "As researcher, I develop mathematical models for a given system (business, project, factory, etc.), and use optimization methods to improve its efficiency (maximize profit, minimize completion time, etc.). In other words, I do mathematical optimization. I also teach Project and Operations Management to B.Sc. and M.Sc. stduents. I am passionate about developing online learning tools and games.",
  birthday: "1989/07/09",
  language: "Persian, English, French (B2)",
};

const Contact = () => {
  return (
    <>
      <Header />
      <section className="contact">
        <PageHeader title="Contact" description="Get in touch" />
        <div className="contactWrap container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <Form />
            </div>
            <div className="col-12 col-lg-6">
              <ContactInfo
                name={personalDetails.name}
                location={personalDetails.location}
                email={personalDetails.email}
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
