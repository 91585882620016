import { useEffect, useState } from "react";
import {
  Header,
  Footer,
  FullscreenLoading,
  PageHeader,
} from "../../components";
import { Link, useLocation } from "react-router-dom";

function Groups(props) {
  const [data, setServerData] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (location.state?.groups) {
      setServerData(location.state.groups);
    }
  }, [location.state?.groups]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "prof/group", {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.success) {
          setServerData(r.data);
        } else {
          console.log(r.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (data != null) {
    return (
      <div>
        <Header />
        <section className="flexSec">
          <PageHeader title="Groups" description="View in time order" />
        </section>
        <section className="newSec">
          <table className="table textWhite">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Group</th>
                <th scope="col">Edit</th>
                <th scope="col">Students</th>
              </tr>
            </thead>
            <tbody>
              {data.map((group, index) => {
                return (
                  <tr
                    key={(
                      "groupDataTable" +
                      data[data.length - 1 - index].name +
                      data[data.length - 1 - index]._id
                    ).toString()}
                  >
                    <th scope="row">{index + 1}</th>
                    <td>{data[data.length - 1 - index].name}</td>
                    <td>
                      <Link
                        to={
                          "/prof/editgroup/" + data[data.length - 1 - index]._id
                        }
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={
                          "/prof/groupusers/" +
                          data[data.length - 1 - index]._id
                        }
                      >
                        <i className="fa-solid fa-users"></i>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Link
            to="/prof/addgroup"
            className="btn btn-outline-success btn-lg my-5"
          >
            <i className="fa-solid fa-plus"></i> Add a Group
          </Link>
        </section>
        <Footer />
      </div>
    );
  } else {
    return <FullscreenLoading />;
  }
}

export default Groups;
