import { useEffect, useState } from "react";
import {
  Header,
  Footer,
  FullscreenLoading,
  PageHeader,
} from "../../components";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

function User() {
  const [data, setServerData] = useState(null);

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    fetch(process.env.REACT_APP_API_ENDPOINT + "user/details", {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.success) {
          setServerData(r.data);
        } else {
          console.log(r.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (data != null) {
    console.log(data);
    return (
      <div>
        <Header />
        <section className="flexSec">
          <PageHeader title="Grades" description="See your results" />
        </section>
        <motion.div
          className="newSec"
          ref={ref}
          initial={{ y: "-10vw", opacity: 0 }}
          animate={inView ? { y: 0, opacity: 1 } : { y: "-10vw", opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <table className="table textWhite">
            <thead>
              <tr>
                <th scope="col" className="text-align-left">
                  Exercise
                </th>
                <th scope="col">Weight</th>
                <th scope="col">Status</th>
                <th scope="col">Grade (out of 20)</th>
                <th scope="col">Submission Date (Paris Time)</th>
              </tr>
            </thead>
            <tbody>
              {data.exerFullNames.map((name, index) => {
                return (
                  <tr key={("userDetails" + name + index).toString()}>
                    <td className="text-align-left">
                      <a
                        className="link-warning link-offset-2 link-underline-opacity-50 link-underline-opacity-100-hover"
                        href={"/content/" + data.exerNames[index]}
                      >
                        {name}
                      </a>
                    </td>
                    <td>{(data.exerPoints[index] / 20) * 100 + "%"}</td>
                    <td
                      className={data.exerActive[index] ? "text-warning" : ""}
                    >
                      {data.exerActive[index] ? "Active" : "Inactive"}
                    </td>
                    <td
                      className={
                        JSON.parse(data.subState[index]) ? "text-warning" : ""
                      }
                    >
                      {JSON.parse(data.subState[index])
                        ? data.grades[index]
                        : "Not Submitted"}
                    </td>
                    <td>
                      {JSON.parse(data.subState[index])
                        ? data.subDates[index]
                        : "Not Submitted"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </motion.div>
        <Footer />
      </div>
    );
  } else {
    return <FullscreenLoading />;
  }
}

export default User;
